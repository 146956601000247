@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.App{
  text-align: left !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #4b31dd;
}

/* Spinner.css */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%; 
  padding-top: 20px;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4b31dd;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.spinner-message {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Custom card styles */
.card {
  background-color: #eeeeee !important;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  border-top: 5px solid #3d2dd7 !important;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.4) !important;
  font-family: "Barlow", sans-serif;
}

.card h2, .card p {
  margin: 0 0 10px 0;
}

.card ul {
  padding: 0;
  list-style-type: none; /* Removes default bullets */
}

.card ul li {
  margin-left: 15px;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0.8em;
}

.card ul li:before {
  content: '➜'; /* Custom bullet */
  color: #4b31dd; /* Button color */
  display: inline-block; 
  width: 0.9em;
  margin-left: -1.9em;
  margin-right: 16px;
}

.tit{
  text-transform: uppercase;
  font-size: 1em !important;
  font-weight: 700;
}

li, p {
  font-family: "Barlow", sans-serif;
}

a{
  text-decoration: none;
  color: #231f20;
}

.btn-primary{
  background-color: #4b31dd !important; /* Button color */
  padding: 7px 12px;
  border-radius: 20px; /* More rounded */
  color: #eeeeee !important; /* White text */
  border: none; /* Remove border */
  cursor: pointer; /* Cursor effect on hover */
  font-size: 1em;
}

.btn-primary:hover{
  background-color: #cbcbcb !important; /* Hover color */
  color: #003366 !important; /* Text color change on hover */
}

.masonry-grid{
  display: flex;
  margin-left: -30px;
  width: auto;
}

.masonry-column{
  padding-left: 30px !important;
  background-clip: padding-box;
}

.slick-slider{
  margin: 20px;
}

.tutorial{
  padding-top: 20px;
  text-align: center;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
}

.data-bullet{
  font-size: 0.6em;
  font-family: 'Roboto Mono';
  margin-bottom: 15px;
  color: #4b31dd;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
  text-align: right;
}
